import React from "react"
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import { BlockRenderer, Grid, Picture, Link, Margin, PageWrapper } from '@components/atoms'
import { GridItem } from '@components/molecules'
import { TransitionMask } from '@components/organisms'
import { PageTitle } from './press'
import { PageProps } from '@types'
import { type } from '@theme'
import { Coordinates } from '@components/molecules/BuddyScroller'


interface BuddyListProps {
  allSanityPost: {
    nodes: Array<{
      slug: {
        current: string
      }
      coordinates?: Coordinates
      title: string
      logo?: {
        asset: {
          url: string
        }
      }
    }>    
  }
}


const AboutPage = ({data: { 
  sanityPage: {
    title,
    gridItems,
    extraAboutFields,
  },
  allSanityPost: {
    nodes
  }
}}: {data: PageProps & BuddyListProps}) => {
  if(!extraAboutFields) return <></>
  const {
    projectCountImage,
    _rawProjectCountDescription,
    _rawProjectsListIntroduction,
    projectCategories,
  } = extraAboutFields
  
  return (
    <>
      <Helmet>
        <title>the buddy system: {title}</title>
      </Helmet>
      <PageWrapper>
        <Margin>
          <Grid>
            <PageTitle>{title}</PageTitle>
            {gridItems.filter(p => !!p).map((item) => (
              <GridItem key={item._key} {...item} />
            ))}
            
            <Subsection>
              <ProjectCountImage>
                <Picture 
                  src={projectCountImage.asset.url} 
                  dimensions={projectCountImage.asset.metadata.dimensions}
                />
              </ProjectCountImage>
              <ProjectCountDescription>
                <BlockRenderer>{_rawProjectCountDescription}</BlockRenderer> 
              </ProjectCountDescription>
              {projectCategories.map((category, i) => (
                <ProjectCategory key={`cat_${i}`} >
                  <Count>{category.count}</Count>
                  <Category>{category.category}</Category>
                  <CategoryDescription>{category.description}</CategoryDescription>
                </ProjectCategory>
              ))}
            </Subsection>
            
            <Subsection>
              <PageTitle>Our Projects</PageTitle>
              <ProjectCountDescription>
                <BlockRenderer>{_rawProjectsListIntroduction}</BlockRenderer> 
              </ProjectCountDescription>
              {nodes.filter(node => node.coordinates && node.logo).map((node) => (
                <Buddy 
                  key={`bigBuddy_${node.slug.current}`} 
                  to={`/${node.slug.current}`}
                  $desktop={node.coordinates!}
                  logo={node.logo?.asset.url}
                />
              ))}
            </Subsection>
            
          </Grid>
        </Margin>
        
        <TransitionMask />
      </PageWrapper>
    </>
  )
}



const Buddy = styled(props => <Link {...props} />)<{
  slug: string,
  $desktop: Coordinates,
  logo: string,
}>`
  position: relative;
  grid-column-end: span 3;
  border: 1px solid white;
  border-radius: 15px;
  background-image: url(${props => props.logo});
  background-size: 70% auto;
  background-position: center center;
  background-repeat: no-repeat;
  color: #ff0000;
  &:last-of-type {
    display: none;
  }
  
  /* (width of canvas - margin - column gap) / percent-of-columns + column gaps within those columns */
  height: calc((100vw - 40px - (5 * 20px)) / 2 + 40px);
  
  @media only screen and (min-width: 744px) {
    height: calc((100vw - 60px - (11 * 30px)) / 4 + 60px);
  }

  @media only screen and (min-width: 1200px) {
    background-size: ${props => (props.$desktop.w > props.$desktop.h * 1.45)
      ? '75% auto'
      : '50% 45%'};
  }

  @media only screen and (min-width: 1440px) {
    height: calc((1380px - 60px - (11 * 30px)) / 4 + 60px);
  }
  
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, color 0.15s ease-in-out;
  &:hover {
    background-color: black;
    border-color: black;
    color: white;
  }
  
  &:before {
    content: '${props => props.alt}';
    position: absolute;
    top: 10px;
    left: 10px;
    ${type.pSerifSmall}
    line-height: 1em;
    @media only screen and (min-width: 1200px) {
      top: 20px;
      left: 20px;
      ${type.headline3}
    }  
  }
`


const Subsection = styled(props => <Grid {...props} />)`
  border-top: 1px solid white;
  padding-top: 1em;
  grid-column-start: 1;
  grid-column-end: span 6;
  @media only screen and (min-width: 744px) {
    grid-column-start: 1;
    grid-column-end: span 12;
    grid-row-gap: 30px;
  }
`

const ProjectCountImage = styled.div`
  grid-column-start: 1;
  grid-column-end: span 6;
`

const ProjectCountDescription = styled.div`
  grid-column-start: 1;
  grid-column-end: span 6;
  @media only screen and (min-width: 744px) {
    grid-column-start: 7;
    grid-column-end: span 6;
  }
`

const ProjectCategory = styled.div`
  grid-column-end: span 3;
`

const Count = styled.div`
  font-family: 'GT_Alpina', 'Georgia', serif;
  font-size: 14rem;
  line-height: 1em;
`

const Category = styled.div`
  ${type.headline3}
  margin-bottom: 1em;
`

const CategoryDescription = styled.p`
  ${type.pSerifSmall}
`


export const query = graphql`
  query AboutPageQuery {
    sanityPage(slug: {current: {eq: "about"}}) {
      ...pageFields
      extraAboutFields {
        projectCountImage {
          asset {
            url
            metadata {
              dimensions {
                width
                height
                aspectRatio
              }
            }
          }
        }
        _rawProjectCountDescription
        _rawProjectsListIntroduction
        projectCategories {
          count
          category
          description
        }
      }
    }
    allSanityPost {
      nodes {
        ...buddyList          
      }
    }
  }
`

export default AboutPage
